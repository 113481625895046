import config from '@/config'
import moment from 'moment'

export const getImgUrl = (sport_id, name, logo) => {
    if (logo.indexOf("http://") == 0 || logo.indexOf("https://") == 0){
        return logo
    }
    if (logo){
        if (sport_id === 10){
            // 排球
            if (name === 'unique-tournament'){
                name = 'competition'
            }
            return config.ballsports.img_prefix + '/volleyball/'+ name +'/' + logo
        }else if (sport_id === 6){
            // 棒球
            if (name === 'unique-tournament'){
                name = 'competition'
            }
            return config.ballsports.img_prefix + '/baseball/'+ name +'/' + logo
        }else if (sport_id === 1){
            if (name === 'unique-tournament'){
                name = 'competition'
            }
            return config.ballsports.img_prefix + '/football/'+ name +'/' + logo 
        }else if (sport_id === 2){
            if (name === 'unique-tournament'){
                name = 'competition'
            }
            return config.ballsports.img_prefix + '/basketball/'+ name +'/' + logo 
        }
        return config.ballsports.img_prefix + '/v2/'+ name +'/' + logo
    }else{
        return ''
    }
}

export const getImgGameUrl = (game_id, table, logo, thumb) => {
    if (logo){
        if (logo.indexOf("http://") == 0 || logo.indexOf("https://") == 0){
            return logo
        }
        let games_dic = {1: "lol", 2: "csgo", 3: "dota", 6:"kog"}
        var game = games_dic[game_id]
        if (game){
            // 排球
            if (thumb){
                return config.games.img_prefix + '/' + game + '/' + table + '/' + logo + "?imageView2/0/w/50/h/50";
            }else{
                return config.games.img_prefix + '/' + game + '/' + table + '/' + logo;
            }
        }
        return ''
    }else{
        return ''
    }
}

// 奥运会logo
export const getOlympicImgUrl = ( logo, dir ) => {
    if (!dir){
        dir = 'olympics'
    }
    if (logo){
        return config.olympics.img_prefix + '/' + dir + '/' + logo;
    }else{
        return '';
    }
}

// 防抖
// var timeout = null
let timeouts = {
    // "createNewTableDatas": null
}
// export const 

class Unit{
    // 1 筛选game_id
    filterGameids( game_ids, game_id ){
        return game_ids.indexOf(game_id) > -1;
    };
    getImgUrl (sport_id, name, logo){
        return getImgUrl (sport_id, name, logo)
    };
    // 电竞图片url拼接
    getImgGameUrl(game_id, table, logo, thumb){
        return getImgGameUrl(game_id, table, logo, thumb);
    };
    tsp2str(tsp, format){
        if (format){
            return moment.unix(tsp).format(format)
        }
        return moment.unix(tsp).format('YYYY-MM-DD HH:mm')
    };
    debounce (fn, wait, _key='default')  {
        if (timeouts[_key] !== null && timeouts[_key] !== undefined) clearTimeout(timeouts[_key])
        timeouts[_key] = setTimeout(fn, wait)
    };
}

export default new Unit(); //用的是export default方法，并且导出的时候就已经实例化了