<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <Menu v-if="$route.meta.keepAlive"></Menu>
    <router-view v-else></router-view>
  </div>
</template>

<script>

import Menu from './components/menu/menu.vue'

export default {
  name: 'App',
  components: {
    Menu,
  }
}
</script>

<style>
#app {
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
  /* overflow: auto !important; */
}

/* .ivu-table .ivu-table-header .ivu-table-wrapper{
  overflow: auto !important;
} */

/* 主标题 */
.title-main{
  font-size: 16px;
  font-weight: bold;
  color: #000;
}

/* 次级标题 */
.title-sub{
  font-size: 14px;
  font-weight: bold;
  color: #464c5b;
}

.title-sub-white{
  font-size: 14px;
  font-weight: bold;
  color: #FFF;
}

/* 小标题 */
.title-little{
  font-size: 12px;
  font-weight: bold;
  color: #464c5b;
}

/* 目录 */
.text-menu{
  font-size: 12px;
  /* font-weight: 1; */
  color: #515a6e;
}

/* 正文 */
.text-main{
  font-size: 12px;
  /* font-weight: 1; */
  color: #2b251a;
}

/* 辅助文本 */
.text-assist{
  font-size: 12px;
  /* font-weight: 1; */
  color: #9ea7b4;
}

/* 失效文本 */
.text-unvalid{
  font-size: 12px;
  /* font-weight: 1; */
  color: #c3cbd6;
}

/* 链接文本 */
.text-link{
  font-size: 12px;
  /* font-weight: 1; */
  color: #3399ff;
}

/* 被锁住的输入框的样式 */
.locked-input .ivu-input {
  background: #eee;
}
/* 被锁住的下拉框的样式 */
.locked-select  .ivu-select-selection  {
  background: #eee;
}

/* 表格样式 */
/* .ivu-table .locked-table-row td {
  background-color: #187;
  color: #fff;
} */

/* 被锁住的Row标签 */
.locked-row {
  background: #eee;
}

/* Form表单间隔 -> 0 */
form .ivu-form-item{
  margin-bottom: 10px;
} 
.ivu-modal-header{
  /* background: #eee; */
  -moz-box-shadow: 0px -1px 5px #888888; /* 老的 Firefox */
  box-shadow: 0px 0px 5px #888888;
} 

/* 表格的列 - 跳转链接 */
.table-link-text{
  text-align: left;
  display:flex;
}

.table-column-intro{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -moz-box;
  -moz-line-clamp: 3;
  -moz-box-orient: vertical;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;    
}

.modal-div-containter{
  max-height:600px;
  /* width:100%; */
  overflow:auto;
}

.ivu-table .demo-table-info-row td{
    background-color: #2db7f5;
    color: #fff;
}
.ivu-table .demo-table-error-row td{
    background-color: #ff6600;
    color: #fff;
}
.ivu-table .demo-table-disable-row td{
    background-color: #00000069;
    color: #fff;
}
.ivu-table td.demo-table-info-column{
    background-color: #2db7f5;
    color: #fff;
}
.ivu-table .demo-table-info-cell-name {
    background-color: #2db7f5;
    color: #fff;
}
.ivu-table .demo-table-info-cell-age {
    background-color: #ff6600;
    color: #fff;
}
.ivu-table .demo-table-info-cell-address {
    background-color: #187;
    color: #fff;
}

.box-border{
  border-width: 1px;
  border-color:#dcdee2;
  border-style:solid;
  background: #f8f8f9;
}
</style>
