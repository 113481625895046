// 全局配置
export default {
    ballsports: { // 小球类运动
        img_prefix: 'https://cdn.sportnanoapi.com',
        status: {
            1: { // 足球
                map:{
                    0: { id: 0, name_en: "ERROR", name_zh: "比赛异常", name: "比赛异常" },
                    1: { id: 1, name_en: "NOT_STARTED", name_zh: "未开赛", name: "未开赛", type: 1 },
                    2: { 'id': 2, 'name_en': 'FIRST_HALF', 'name_zh': '上半场', 'name': '上半场', type: 2 }, 
                    3: {'id': 3, 'name_en': 'INNINGS', 'name_zh': '中场', 'name': '中场', type: 2} ,
                    4: { 'id': 4, 'name_en': 'SECOND_HALF', 'name_zh': '下半场', 'name': '下半场', type: 2 },
                    5: {'id': 5, 'name_en': 'OVERTIME', 'name_zh': '加时赛', 'name': '加时赛', type: 2}, 
                    7: { 'id': 7, 'name_en': 'PENALTIES', 'name_zh': '点球决胜', 'name': '点球决胜' , type: 2}, 
                    8: { id: 8, name_en: "ENDED", name_zh: "完场", name: "完场", type: 3 },
                    9: { id: 9, name_en: "DELAYED", name_zh: "推迟", name: "推迟" },
                    10: { id: 10, name_en: "INTERRUPTED", name_zh: "中断", name: "中断" },
                    11: { id: 10, name_en: "腰斩", name_zh: "腰斩", name: "腰斩" },
                    12: { id: 12, name_en: "CANCELED", name_zh: "比赛取消", name: "比赛取消" },
                    13: { id: 13, name_en: "待定", name_zh: "待定", name: "待定" },
                },
            },
            2: { // 篮球
                map:{
                    0: { id: 0, name_en: "ERROR", name_zh: "比赛异常", name: "比赛异常" },
                    1: { id: 1, name_en: "NOT_STARTED", name_zh: "未开赛", name: "未开赛", type: 1 },
                    2: {'id': 2, 'name_en': 'FIRST_PERIOD', 'name_zh': '第一节', 'name': '第一节', type: 2}, 
                    3: {'id': 3, 'name_en': 'FIRST_FINISHED', 'name_zh': '第一节完', 'name': '第一节完', type: 2}, 
                    4: {'id': 4, 'name_en': 'SECOND_PERIOD', 'name_zh': '第二节', 'name': '第二节', type: 2}, 
                    5: {'id': 5, 'name_en': 'SECOND_FINISHED', 'name_zh': '第二节完', 'name': '第二节完', type: 2}, 
                    6: {'id': 6, 'name_en': 'THIRD_PERIOD', 'name_zh': '第三节', 'name': '第三节', type: 2}, 
                    7: {'id': 7, 'name_en': 'THIRD_FINISHED', 'name_zh': '第三节完', 'name': '第三节完', type: 2}, 
                    8: {'id': 8, 'name_en': 'FORTH_PERIOD', 'name_zh': '第四节', 'name': '第四节', type: 2}, 
                    9: {'id': 9, 'name_en': 'OVERTIME', 'name_zh': '加时', 'name': '加时', type: 2},
                    10: { id: 10, name_en: "ENDED", name_zh: "完场", name: "完场", type: 3 },
                    11: { id: 11, name_en: "INTERRUPTED", name_zh: "中断", name: "中断" },
                    12: { id: 12, name_en: "CANCELED", name_zh: "取消", name: "取消" },
                    13: { id: 13, name_en: "POSTPONED", name_zh: "延期", name: "延期" },
                    14: { id: 14, name_en: "腰斩", name_zh: "腰斩", name: "腰斩" },
                    15: { id: 15, name_en: "待定", name_zh: "待定", name: "待定" },
                },
            },
            // 运动id
            3: {  // 网球 tennis
                map: {
                    0: { id: 0, name_en: "ERROR", name_zh: "比赛异常", name: "比赛异常" },
                    1: { id: 1, name_en: "NOT_STARTED", name_zh: "未开赛", name: "未开赛", type: 1 },
                    3: { id: 3, name_en: "IN_PROGRESS", name_zh: "进行中", name: "进行中", type: 2 },
                    14: { id: 14, name_en: "POSTPONED", name_zh: "延期", name: "延期" },
                    15: { id: 15, name_en: "DELAYED", name_zh: "推迟开赛", name: "推迟开赛" },
                    16: { id: 16, name_en: "CANCELED", name_zh: "比赛取消", name: "比赛取消" },
                    17: { id: 17, name_en: "INTERRUPTED", name_zh: "中断", name: "中断" },
                    18: { id: 18, name_en: "STOPED", name_zh: "停赛", name: "停赛" },
                    19: { id: 19, name_en: "ABANDONED", name_zh: "弃赛", name: "弃赛" },
                    20: { id: 20, name_en: "WALKOVER", name_zh: "不战而胜", name: "不战而胜", type: 3 },
                    21: { id: 21, name_en: "RETIRED", name_zh: "退出", name: "退出" },
                    22: { id: 22, name_en: "WALKOVER1", name_zh: "选手1不战而胜", name: "选手1不战而胜", type: 3 },
                    23: { id: 23, name_en: "WALKOVER2", name_zh: "选手2不战而胜", name: "选手2不战而胜", type: 3 },
                    24: { id: 24, name_en: "RETIRED1", name_zh: "选手1退赛", name: "选手1退赛", type: 3 },
                    25: { id: 25, name_en: "RETIRED2", name_zh: "选手2退赛", name: "选手2退赛", type: 3 },
                    26: { id: 26, name_en: "DEFAULTED1", name_zh: "选手1违约，选手2获胜", name: "选手1违约，选手2获胜", type: 3 },
                    27: { id: 27, name_en: "DEFAULTED2", name_zh: "选手2违约，选手1获胜", name: "选手2违约，选手1获胜", type: 3 },
                    51: { id: 51, name_en: "FIRST_SET", name_zh: "第一盘", name: "第一盘", type: 2 },
                    52: { id: 52, name_en: "SECOND_SET", name_zh: "第二盘", name: "第二盘", type: 2 },
                    53: { id: 53, name_en: "THIRD_SET", name_zh: "第三盘", name: "第三盘", type: 2 },
                    54: { id: 54, name_en: "FOURTH_SET", name_zh: "第四盘", name: "第四盘", type: 2 },
                    55: { id: 55, name_en: "FIFTH_SET", name_zh: "第五盘", name: "第五盘", type: 2 },
                    99: { id: 99, name_en: "待定", name_zh: "待定", name: "待定" },
                    100: { id: 100, name_en: "ENDED", name_zh: "完场", name: "完场", type: 3 },
                }
            },
            5: {  // 板球 cricket
                map: {
                    0: { id: 0, name_en: "ERROR", name_zh: "比赛异常", name: "比赛异常" },
                    1: { id: 1, name_en: "NOT_STARTED", name_zh: "未开赛", name: "未开赛", type: 1 },
                    532: { id: 532, name_en: "FIRST_INNINGS_HOME_TEAM", name_zh: "主队第一局", name: "主队第一局", type: 2 },
                    533 : {'id': 533, 'name_en': 'FIRST_INNINGS_AWAY_TEAM', 'name_zh': '客队第一局', 'name': '客队第一局', type: 2} ,
                    534 : {'id': 534, 'name_en': 'SECOND_INNINGS_HOME_TEAM', 'name_zh': '主队第二局', 'name': '主队第二局', type: 2} ,
                    535 : {'id': 535, 'name_en': 'SECOND_INNINGS_AWAY_TEAM', 'name_zh': '客队第二局', 'name': '客队第二局', type: 2} ,
                    536 : {'id': 536, 'name_en': 'AWAITING_SUPER_OVER', 'name_zh': '等待超级轮', 'name': '等待超级轮', type: 2} ,
                    537 : {'id': 537, 'name_en': 'SUPER_OVER_HOME_TEAM', 'name_zh': '主队超级轮', 'name': '主队超级轮', type: 2} ,
                    538 : {'id': 538, 'name_en': 'SUPER_OVER_AWAY_TEAM', 'name_zh': '客队超级轮', 'name': '客队超级轮', type: 2} ,
                    539 : {'id': 539, 'name_en': 'AFTER_SUPER_OVER', 'name_zh': '超级轮赛后', 'name': '超级轮赛后', type: 3} ,
                    540 : {'id': 540, 'name_en': 'INNINGS_BREAK', 'name_zh': '中场休息', 'name': '中场休息', type: 2} ,
                    541 : {'id': 541, 'name_en': 'SUPER_OVER_BREAK', 'name_zh': '超级轮中场休息', 'name': '超级轮中场休息', type: 2} ,
                    542 : {'id': 542, 'name_en': 'LUNCH_BREAK', 'name_zh': '午休', 'name': '午休', type: 2} ,
                    543 : {'id': 543, 'name_en': 'TEA_BREAK', 'name_zh': '茶歇', 'name': '茶歇', type: 2} ,
                    544 : {'id': 544, 'name_en': 'STUMPS', 'name_zh': '球击门柱使击球手出局', 'name': '球击门柱使击球手出局', type: 2} ,
                    99: { id: 99, name_en: "待定", name_zh: "待定", name: "待定" },
                    100: { id: 100, name_en: "ENDED", name_zh: "完场", name: "完场", type: 3 },
                    14: { id: 14, name_en: "POSTPONED", name_zh: "延期", name: "延期" },
                    15: { id: 15, name_en: "DELAYED", name_zh: "推迟开赛", name: "推迟开赛" },
                    16: { id: 16, name_en: "CANCELED", name_zh: "比赛取消", name: "比赛取消" },
                    17: { id: 17, name_en: "INTERRUPTED", name_zh: "中断", name: "中断" },
                    18: { id: 18, name_en: "STOPED", name_zh: "停赛", name: "停赛" },
                    19: { id: 19, name_en: "ABANDONED", name_zh: "弃赛", name: "弃赛" },
                    20: { id: 20, name_en: "WALKOVER", name_zh: "不战而胜", name: "不战而胜", type: 3 },
                    21: { id: 21, name_en: "RETIRED", name_zh: "退出", name: "退出" },
                }
            },
            6: {
                // 棒球 baseball
                map: {
                    0: { id: 0, name_en: "ERROR", name_zh: "比赛异常", name: "比赛异常" },
                    1: { 'id': 1, 'name_en': 'NOT_STARTED', 'name_zh': '未开赛', 'name': '未开赛', type: 1 },
                    432: { 'id': 432, 'name_en': 'FIRST_INNING_TOP', 'name_zh': '第一局上半场', 'name': '第一局上半场', type: 2 },
                    452: { 'id': 452, 'name_en': 'BREAK_TOP1_BOTTOM1', 'name_zh': '第一局中场休息', 'name': '第一局中场休息', type: 2 },
                    433: { 'id': 433, 'name_en': 'FIRST_INNING_BOTTOM', 'name_zh': '第一局下半场', 'name': '第一局下半场', type: 2 },
                    453: { 'id': 453, 'name_en': 'BREAK_TOP2_BOTTOM1', 'name_zh': '第一局结束', 'name': '第一局结束', type: 2 },
                    434: { 'id': 434, 'name_en': 'SECOND_INNING_TOP', 'name_zh': '第二局上半场', 'name': '第二局上半场', type: 2 },
                    454: { 'id': 454, 'name_en': 'BREAK_TOP2_BOTTOM2', 'name_zh': '第二局中场休息', 'name': '第二局中场休息', type: 2 },
                    435: { 'id': 435, 'name_en': 'SECOND_INNING_BOTTOM', 'name_zh': '第二局下半场', 'name': '第二局下半场', type: 2 },
                    455: { 'id': 455, 'name_en': 'BREAK_TOP3_BOTTOM2', 'name_zh': '第二局结束', 'name': '第二局结束', type: 2 },
                    436: { 'id': 436, 'name_en': 'THIRD_INNING_TOP', 'name_zh': '第三局上半场', 'name': '第三局上半场', type: 2 },
                    456: { 'id': 456, 'name_en': 'BREAK_TOP3_BOTTOM3', 'name_zh': '第三局中场休息', 'name': '第三局中场休息', type: 2 },
                    437: { 'id': 437, 'name_en': 'THIRD_INNING_BOTTOM', 'name_zh': '第三局下半场', 'name': '第三局下半场', type: 2 },
                    457: { 'id': 457, 'name_en': 'BREAK_TOP4_BOTTOM3', 'name_zh': '第三局结束', 'name': '第三局结束', type: 2 },
                    438: { 'id': 438, 'name_en': 'FOURTH_INNING_TOP', 'name_zh': '第四局上半场', 'name': '第四局上半场', type: 2 },
                    458: { 'id': 458, 'name_en': 'BREAK_TOP4_BOTTOM4', 'name_zh': '第四局中场休息', 'name': '第四局中场休息', type: 2 },
                    439: { 'id': 439, 'name_en': 'FOURTH_INNING_BOTTOM', 'name_zh': '第四局下半场', 'name': '第四局下半场', type: 2 },
                    459: { 'id': 459, 'name_en': 'BREAK_TOP5_BOTTOM4', 'name_zh': '第四局结束', 'name': '第四局结束', type: 2 },
                    440: { 'id': 440, 'name_en': 'FIFTH_INNING_TOP', 'name_zh': '第五局上半场', 'name': '第五局上半场', type: 2 },
                    460: { 'id': 460, 'name_en': 'BREAK_TOP5_BOTTOM5', 'name_zh': '第五局中场休息', 'name': '第五局中场休息', type: 2 },
                    411: { 'id': 411, 'name_en': 'FIFTH_INNING_BOTTOM', 'name_zh': '第五局下半场', 'name': '第五局下半场', type: 2 },
                    461: { 'id': 461, 'name_en': 'BREAK_TOP6_BOTTOM5', 'name_zh': '第五局结束', 'name': '第五局结束', type: 2 },
                    412: { 'id': 412, 'name_en': 'SIXTH_INNING_TOP', 'name_zh': '第六局上半场', 'name': '第六局上半场', type: 2 },
                    462: { 'id': 462, 'name_en': 'BREAK_TOP6_BOTTOM6', 'name_zh': '第六局中场休息', 'name': '第六局中场休息', type: 2 },
                    413: { 'id': 413, 'name_en': 'SIXTH_INNING_BOTTOM', 'name_zh': '第六局下半场', 'name': '第六局下半场', type: 2 },
                    463: { 'id': 463, 'name_en': 'BREAK_TOP7_BOTTOM6', 'name_zh': '第六局结束', 'name': '第六局结束', type: 2 },
                    414: { 'id': 414, 'name_en': 'SEVENTH_INNING_TOP', 'name_zh': '第七局上半场', 'name': '第七局上半场', type: 2 },
                    464: { 'id': 464, 'name_en': 'BREAK_TOP7_BOTTOM7', 'name_zh': '第七局中场休息', 'name': '第七局中场休息', type: 2 },
                    415: { 'id': 415, 'name_en': 'SEVENTH_INNING_BOTTOM', 'name_zh': '第七局下半场', 'name': '第七局下半场', type: 2 },
                    465: { 'id': 465, 'name_en': 'BREAK_TOP8_BOTTOM7', 'name_zh': '第七局结束', 'name': '第七局结束', type: 2 },
                    416: { 'id': 416, 'name_en': 'EIGHTH_INNING_TOP', 'name_zh': '第八局上半场', 'name': '第八局上半场', type: 2 },
                    466: { 'id': 466, 'name_en': 'BREAK_TOP8_BOTTOM8', 'name_zh': '第八局中场休息', 'name': '第八局中场休息', type: 2 },
                    417: { 'id': 417, 'name_en': 'EIGHTH_INNING_BOTTOM', 'name_zh': '第八局下半场', 'name': '第八局下半场', type: 2 },
                    467: { 'id': 467, 'name_en': 'BREAK_TOP9_BOTTOM8', 'name_zh': '第八局结束', 'name': '第八局结束', type: 2 },
                    418: { 'id': 418, 'name_en': 'NINTH_INNING_TOP', 'name_zh': '第九局上半场', 'name': '第九局上半场', type: 2 },
                    468: { 'id': 468, 'name_en': 'BREAK_TOP9_BOTTOM9', 'name_zh': '第九局中场休息', 'name': '第九局中场休息', type: 2 },
                    419: { 'id': 419, 'name_en': 'NINTH_INNING_BOTTOM', 'name_zh': '第九局下半场', 'name': '第九局下半场', type: 2 },
                    469: { 'id': 469, 'name_en': 'BREAK_TOPEI_BOTTOM9', 'name_zh': '第九局结束', 'name': '第九局结束', type: 2 },
                    420: { 'id': 420, 'name_en': 'EXTRA_INNING_TOP', 'name_zh': '延长赛上半场', 'name': '延长赛上半场', type: 2 },
                    470: { 'id': 470, 'name_en': 'BREAK_TOPEI_BOTTOMEI', 'name_zh': '延长赛中场休息', 'name': '延长赛中场休息', type: 2 },
                    421: { 'id': 421, 'name_en': 'EXTRA_INNING_BOTTOM', 'name_zh': '延长赛下半场', 'name': '延长赛下半场', type: 2 },
                    99: { id: 99, name_en: "待定", name_zh: "待定", name: "待定" },
                    100: { 'id': 100, 'name_en': 'ENDED', 'name_zh': '完场', 'name': '完场', type: 3 },
                    14: { id: 14, name_en: "POSTPONED", name_zh: "延期", name: "延期" },
                    15: { id: 15, name_en: "DELAYED", name_zh: "推迟开赛", name: "推迟开赛" },
                    16: { id: 16, name_en: "CANCELED", name_zh: "比赛取消", name: "比赛取消" },
                    17: { id: 17, name_en: "INTERRUPTED", name_zh: "中断", name: "中断" },
                    18: { id: 18, name_en: "STOPED", name_zh: "停赛", name: "停赛" },
                    19: { id: 19, name_en: "ABANDONED", name_zh: "弃赛", name: "弃赛" },
                    20: { id: 20, name_en: "WALKOVER", name_zh: "不战而胜", name: "不战而胜", type: 3 },
                    21: { id: 21, name_en: "RETIRED", name_zh: "退出", name: "退出" },
                },
            },
            7: {
                // 手球 handball
                map: {
                    0: { id: 0, name_en: "ERROR", name_zh: "比赛异常", name: "比赛异常" },
                    1: { 'id': 1, 'name_en': 'NOT_STARTED', 'name_zh': '未开赛', 'name': '未开赛' , type: 1},
                    5: { 'id': 5, 'name_en': 'HALFTIME', 'name_zh': '半场', 'name': '半场', type: 2 }, 
                    6: { 'id': 6, 'name_en': 'AWAITING_OT', 'name_zh': '等待加时赛', 'name': '等待加时赛' , type: 2}, 
                    7: { 'id': 7, 'name_en': 'OT_HALFTIME', 'name_zh': '加时赛中场', 'name': '加时赛中场', type: 2 }, 
                    8: { 'id': 8, 'name_en': 'AWAITING_PENALTIES', 'name_zh': '等待点球决胜', 'name': '等待点球决胜' , type: 2}, 
                    11: { 'id': 11, 'name_en': 'FIRST_HALF_OT', 'name_zh': '加时第一节', 'name': '加时第一节', type: 2 }, 
                    12: { 'id': 12, 'name_en': 'SECOND_HALF_OT', 'name_zh': '加时半场', 'name': '加时半场' , type: 2}, 
                    13: { 'id': 13, 'name_en': 'PENALTY_SHOOTING', 'name_zh': '点球', 'name': '点球' , type: 2}, 
                    35: { 'id': 35, 'name_en': 'FIRST_HALF', 'name_zh': '上半场', 'name': '上半场', type: 2 }, 
                    36: { 'id': 36, 'name_en': 'SECOND_HALF', 'name_zh': '下半场', 'name': '下半场', type: 2 }, 
                    99: { id: 99, name_en: "待定", name_zh: "待定", name: "待定" },
                    100: { 'id': 100, 'name_en': 'ENDED', 'name_zh': '完场', 'name': '完场' , type: 3}, 
                    105: { 'id': 105, 'name_en': 'AFTER_OT', 'name_zh': '加时赛后', 'name': '加时赛后' , type: 3}, 
                    110: { 'id': 110, 'name_en': 'AFTER_PENALTIES', 'name_zh': '点球决胜后', 'name': '点球决胜后' , type: 3}, 
                    14: { id: 14, name_en: "POSTPONED", name_zh: "延期", name: "延期" },
                    15: { id: 15, name_en: "DELAYED", name_zh: "推迟开赛", name: "推迟开赛" },
                    16: { id: 16, name_en: "CANCELED", name_zh: "比赛取消", name: "比赛取消" },
                    17: { id: 17, name_en: "INTERRUPTED", name_zh: "中断", name: "中断" },
                    18: { id: 18, name_en: "STOPED", name_zh: "停赛", name: "停赛" },
                    19: { id: 19, name_en: "ABANDONED", name_zh: "弃赛", name: "弃赛" },
                    20: { id: 20, name_en: "WALKOVER", name_zh: "不战而胜", name: "不战而胜", type: 3 },
                    21: { id: 21, name_en: "RETIRED", name_zh: "退出", name: "退出" },
                },
            },
            8: {
                // 冰球 ice_hockey
                map: {
                    0: { id: 0, name_en: "ERROR", name_zh: "比赛异常", name: "比赛异常" },
                    1: {'id': 1, 'name_en': 'NOT_STARTED', 'name_zh': '未开赛', 'name': '未开赛', type: 1}, 
                    30: {'id': 30, 'name_en': 'FIRST_PERIOD', 'name_zh': '第一节', 'name': '第一节', type: 2}, 
                    331: {'id': 331, 'name_en': 'FIRST_PAUSE', 'name_zh': '第一节暂停', 'name': '第一节暂停', type: 2}, 
                    31: {'id': 31, 'name_en': 'SECOND_PERIOD', 'name_zh': '第二节', 'name': '第二节', type: 2}, 
                    332: {'id': 332, 'name_en': 'SECOND_PAUSE', 'name_zh': '第二节暂停', 'name': '第二节暂停', type: 2}, 
                    32: {'id': 32, 'name_en': 'THIRD_PERIOD', 'name_zh': '第三节', 'name': '第三节', type: 2}, 
                    99: { id: 99, name_en: "待定", name_zh: "待定", name: "待定" },
                    100: {'id': 100, 'name_en': 'ENDED', 'name_zh': '完场', 'name': '完场', type: 3}, 
                    6: {'id': 6, 'name_en': 'AWAITING_OT', 'name_zh': '等待加时开始', 'name': '等待加时开始', type: 2}, 
                    10: {'id': 10, 'name_en': 'OVERTIME', 'name_zh': '加时赛', 'name': '加时赛', type: 2}, 
                    105: {'id': 105, 'name_en': 'AFTER_OT', 'name_zh': '加时赛结束', 'name': '加时赛结束', type: 3}, 
                    8: {'id': 8, 'name_en': 'AWAITING_PENALTIES', 'name_zh': '等待点球大战', 'name': '等待点球大战', type: 2}, 
                    13: {'id': 13, 'name_en': 'PENALTY_SHOOTING', 'name_zh': '点球大战', 'name': '点球大战', type: 2}, 
                    110: {'id': 110, 'name_en': 'AFTER_PENALTIES', 'name_zh': '点球大战结束', 'name': '点球大战结束', type: 3}, 
                    14: { id: 14, name_en: "POSTPONED", name_zh: "延期", name: "延期" },
                    15: { id: 15, name_en: "DELAYED", name_zh: "推迟开赛", name: "推迟开赛" },
                    16: { id: 16, name_en: "CANCELED", name_zh: "比赛取消", name: "比赛取消" },
                    17: { id: 17, name_en: "INTERRUPTED", name_zh: "中断", name: "中断" },
                    18: { id: 18, name_en: "STOPED", name_zh: "停赛", name: "停赛" },
                    19: { id: 19, name_en: "ABANDONED", name_zh: "弃赛", name: "弃赛" },
                    20: { id: 20, name_en: "WALKOVER", name_zh: "不战而胜", name: "不战而胜", type: 3 },
                    21: { id: 21, name_en: "RETIRED", name_zh: "退出", name: "退出" },
                }
            },
            10: { // 排球 volleyball
                map: {
                    0: { id: 0, name_en: "ERROR", name_zh: "比赛异常", name: "比赛异常" },
                    1: { 'id': 1, 'name_zh': '未开赛', 'name_en': 'NOT_STARTED', 'name': '未开赛', type: 1 },
                    432: { 'id': 432, 'name_zh': '第一盘', 'name_en': 'FIRST_SET', 'name': '第一盘', type: 2 },
                    434: { 'id': 434, 'name_zh': '第二盘', 'name_en': 'SECOND_SET', 'name': '第二盘', type: 2 },
                    436: { 'id': 436, 'name_zh': '第三盘', 'name_en': 'THIRD_SET', 'name': '第三盘', type: 2 },
                    438: { 'id': 438, 'name_zh': '第四盘', 'name_en': 'FOURTH_SET', 'name': '第四盘', type: 2 },
                    440: { 'id': 440, 'name_zh': '第五盘', 'name_en': 'FIFTH_SET', 'name': '第五盘', type: 2 },
                    99: { id: 99, name_en: "待定", name_zh: "待定", name: "待定" },
                    100: { 'id': 100, 'name_zh': '完场', 'name_en': 'ENDED', 'name': '完场', type: 3 },
                    14: { id: 14, name_en: "POSTPONED", name_zh: "延期", name: "延期" },
                    15: { id: 15, name_en: "DELAYED", name_zh: "推迟开赛", name: "推迟开赛" },
                    16: { id: 16, name_en: "CANCELED", name_zh: "比赛取消", name: "比赛取消" },
                    17: { id: 17, name_en: "INTERRUPTED", name_zh: "中断", name: "中断" },
                    18: { id: 18, name_en: "STOPED", name_zh: "停赛", name: "停赛" },
                    19: { id: 19, name_en: "ABANDONED", name_zh: "弃赛", name: "弃赛" },
                    20: { id: 20, name_en: "WALKOVER", name_zh: "不战而胜", name: "不战而胜", type: 3 },
                    21: { id: 21, name_en: "RETIRED", name_zh: "退出", name: "退出" },
                }
            },
            11: {
                // 乒乓球 table_tennis
                map: { 
                    0: { id: 0, name_en: "ERROR", name_zh: "比赛异常", name: "比赛异常" },
                    1: { 'id': 1, 'name_en': 'NOT_STARTED', 'name_zh': '未开赛', 'name': '未开赛' , type: 1}, 
                    3: { id: 3, name_en: "IN_PROGRESS", name_zh: "进行中", name: "进行中", type: 2 },
                    51: { 'id': 51, 'name_en': 'FIRST_SET', 'name_zh': '第一盘', 'name': '第一盘' , type: 2}, 
                    331: { 'id': 331, 'name_en': 'FIRST_PAUSE', 'name_zh': '第一盘结束', 'name': '第一盘结束' , type: 2}, 
                    52: { 'id': 52, 'name_en': 'SECOND_SET', 'name_zh': '第二盘', 'name': '第二盘' , type: 2}, 
                    332: { 'id': 332, 'name_en': 'SECOND_PAUSE', 'name_zh': '第二盘结束', 'name': '第二盘结束' , type: 2}, 
                    53: { 'id': 53, 'name_en': 'THIRD_SET', 'name_zh': '第三盘', 'name': '第三盘' , type: 2}, 
                    333: { 'id': 333, 'name_en': 'THIRD_PAUSE', 'name_zh': '第三盘结束', 'name': '第三盘结束' , type: 2}, 
                    54: { 'id': 54, 'name_en': 'FOURTH_SET', 'name_zh': '第四盘', 'name': '第四盘' , type: 2}, 
                    334: { 'id': 334, 'name_en': 'FOURTH_PAUSE', 'name_zh': '第四盘结束', 'name': '第四盘结束' , type: 2}, 
                    55: { 'id': 55, 'name_en': 'FIFTH_SET', 'name_zh': '第五盘', 'name': '第五盘' , type: 2}, 
                    335: { 'id': 335, 'name_en': 'FIFTH_PAUSE', 'name_zh': '第五盘结束', 'name': '第五盘结束' , type: 2}, 
                    472: { 'id': 472, 'name_en': 'SIXTH_SET', 'name_zh': '第六盘', 'name': '第六盘' , type: 2}, 
                    336: { 'id': 336, 'name_en': 'SIXTH_PAUSE', 'name_zh': '第六盘结束', 'name': '第六盘结束', type: 2 }, 
                    473: { 'id': 473, 'name_en': 'SEVENTH_SET', 'name_zh': '第七盘', 'name': '第七盘' , type: 2}, 
                    99: { id: 99, name_en: "待定", name_zh: "待定", name: "待定" },
                    100: { 'id': 100, 'name_en': 'ENDED', 'name_zh': '完场', 'name': '完场' , type: 3}, 
                    22: { 'id': 22, 'name_en': 'WALKOVER1', 'name_zh': '选手1不战而胜', 'name': '选手1不战而胜' , type: 3}, 
                    23: { 'id': 23, 'name_en': 'WALKOVER2', 'name_zh': '选手2不战而胜', 'name': '选手2不战而胜' , type: 3}, 
                    24: { 'id': 24, 'name_en': 'RETIRED1', 'name_zh': '选手1退赛', 'name': '选手1退赛' , type: 3}, 
                    25: { 'id': 25, 'name_en': 'RETIRED2', 'name_zh': '选手2退赛', 'name': '选手2退赛' , type: 3}, 
                    26: { 'id': 26, 'name_en': 'DEFAULTED1', 'name_zh': '选手1违约，选手2获胜', 'name': '选手1违约，选手2获胜' , type: 3}, 
                    27: { 'id': 27, 'name_en': 'DEFAULTED2', 'name_zh': '选手2违约，选手1获胜', 'name': '选手2违约，选手1获胜' , type: 3}, 
                    14: { id: 14, name_en: "POSTPONED", name_zh: "延期", name: "延期" },
                    15: { id: 15, name_en: "DELAYED", name_zh: "推迟开赛", name: "推迟开赛" },
                    16: { id: 16, name_en: "CANCELED", name_zh: "比赛取消", name: "比赛取消" },
                    17: { id: 17, name_en: "INTERRUPTED", name_zh: "中断", name: "中断" },
                    18: { id: 18, name_en: "STOPED", name_zh: "停赛", name: "停赛" },
                    19: { id: 19, name_en: "ABANDONED", name_zh: "弃赛", name: "弃赛" },
                    20: { id: 20, name_en: "WALKOVER", name_zh: "不战而胜", name: "不战而胜", type: 3 },
                    21: { id: 21, name_en: "RETIRED", name_zh: "退出", name: "退出" },
                }
            },
            17: {
                // 美式橄榄球 american_football
                map: {
                    0: { id: 0, name_en: "ERROR", name_zh: "比赛异常", name: "比赛异常" },
                    1 : {'id': 1, 'name_en': 'NOT_STARTED', 'name_zh': '未开赛', 'name': '未开赛', type: 1 } ,
                    44 : {'id': 44, 'name_en': 'FIRST_QUARTER', 'name_zh': '第一节', 'name': '第一节', type: 2 } ,
                    331 : {'id': 331, 'name_en': 'FIRST_PAUSE', 'name_zh': '第一节结束', 'name': '第一节结束', type: 2} ,
                    45 : {'id': 45, 'name_en': 'SECOND_QUARTER', 'name_zh': '第二节', 'name': '第二节', type: 2} ,
                    332 : {'id': 332, 'name_en': 'SECOND_PAUSE', 'name_zh': '第二节结束', 'name': '第二节结束', type: 2} ,
                    46 : {'id': 46, 'name_en': 'THIRD_QUARTER', 'name_zh': '第三节', 'name': '第三节', type: 2} ,
                    333 : {'id': 333, 'name_en': 'THIRD_PAUSE', 'name_zh': '第三节结束', 'name': '第三节结束', type: 2} ,
                    47 : {'id': 47, 'name_en': 'FOURTH_QUARTER', 'name_zh': '第四节', 'name': '第四节', type: 2} ,
                    6 : {'id': 6, 'name_en': 'AWAITING_OT', 'name_zh': '等待加时赛', 'name': '等待加时赛', type: 2} ,
                    10 : {'id': 10, 'name_en': 'OVERTIME', 'name_zh': '加时赛', 'name': '加时赛', type: 2} ,
                    105 : {'id': 105, 'name_en': 'AFTER_OT', 'name_zh': '加时赛后', 'name': '加时赛后', type: 3} ,
                    99: { id: 99, name_en: "待定", name_zh: "待定", name: "待定" },
                    100: { 'id': 100, 'name_zh': '完场', 'name_en': 'ENDED', 'name': '完场', type: 3 },
                    14: { id: 14, name_en: "POSTPONED", name_zh: "延期", name: "延期" },
                    15: { id: 15, name_en: "DELAYED", name_zh: "推迟开赛", name: "推迟开赛" },
                    16: { id: 16, name_en: "CANCELED", name_zh: "比赛取消", name: "比赛取消" },
                    17: { id: 17, name_en: "INTERRUPTED", name_zh: "中断", name: "中断" },
                    18: { id: 18, name_en: "STOPED", name_zh: "停赛", name: "停赛" },
                    19: { id: 19, name_en: "ABANDONED", name_zh: "弃赛", name: "弃赛" },
                    20: { id: 20, name_en: "WALKOVER", name_zh: "不战而胜", name: "不战而胜", type: 3 },
                    21: { id: 21, name_en: "RETIRED", name_zh: "退出", name: "退出" },
                }
            },
            19: {
                // 斯诺克 snooker
                map: { 
                    0: { id: 0, name_en: "ERROR", name_zh: "比赛异常", name: "比赛异常" },
                    1: { 'id': 1, 'name_en': 'NOT_STARTED', 'name_zh': '未开赛', 'name': '未开赛' , type: 1}, 
                    3: { 'id': 3, 'name_en': 'IN_PROGRESS', 'name_zh': '进行中', 'name': '进行中' , type: 2}, 
                    4: { 'id': 4, 'name_en': 'PAUSE', 'name_zh': '暂停', 'name': '暂停' , type: 2}, 
                    476: { 'id': 476, 'name_en': 'SESSION_BREAK', 'name_zh': '局间休息', 'name': '局间休息' , type: 2}, 
                    22: { 'id': 22, 'name_en': 'WALKOVER1', 'name_zh': '选手1不战而胜', 'name': '选手1不战而胜' , type: 3}, 
                    23: { 'id': 23, 'name_en': 'WALKOVER2', 'name_zh': '选手2不战而胜', 'name': '选手2不战而胜' , type: 3}, 
                    24: { 'id': 24, 'name_en': 'RETIRED1', 'name_zh': '选手1退赛', 'name': '选手1退赛' , type: 3}, 
                    25: { 'id': 25, 'name_en': 'RETIRED2', 'name_zh': '选手2退赛', 'name': '选手2退赛' , type: 3}, 
                    26: { 'id': 26, 'name_en': 'DEFAULTED1', 'name_zh': '选手1违约，选手2获胜', 'name': '选手1违约，选手2获胜' , type: 3}, 
                    27: { 'id': 27, 'name_en': 'DEFAULTED2', 'name_zh': '选手2违约，选手1获胜', 'name': '选手2违约，选手1获胜' , type: 3}, 
                    99: { id: 99, name_en: "待定", name_zh: "待定", name: "待定" },
                    100: { 'id': 100, 'name_en': 'ENDED', 'name_zh': '完场', 'name': '完场' , type: 3}, 
                    14: { id: 14, name_en: "POSTPONED", name_zh: "延期", name: "延期" },
                    15: { id: 15, name_en: "DELAYED", name_zh: "推迟开赛", name: "推迟开赛" },
                    16: { id: 16, name_en: "CANCELED", name_zh: "比赛取消", name: "比赛取消" },
                    17: { id: 17, name_en: "INTERRUPTED", name_zh: "中断", name: "中断" },
                    18: { id: 18, name_en: "STOPED", name_zh: "停赛", name: "停赛" },
                    19: { id: 19, name_en: "ABANDONED", name_zh: "弃赛", name: "弃赛" },
                    20: { id: 20, name_en: "WALKOVER", name_zh: "不战而胜", name: "不战而胜", type: 3 },
                    21: { id: 21, name_en: "RETIRED", name_zh: "退出", name: "退出" },
                }

            },
            22: {
                // 水球 waterpolo
                map:{
                    0: { id: 0, name_en: "ERROR", name_zh: "比赛异常", name: "比赛异常" },
                    1: { 'id': 1, 'name_en': 'NOT_STARTED', 'name_zh': '未开赛', 'name': '未开赛' , type: 1}, 
                    3: { id: 3, name_en: "IN_PROGRESS", name_zh: "进行中", name: "进行中", type: 2 },
                    110: { 'id': 110, 'name_en': 'AFTER_PENALTIES', 'name_zh': '点球决胜后', 'name': '点球决胜后' , type: 3}, 
                    105: { 'id': 105, 'name_en': 'AFTER_OT', 'name_zh': '加时赛后', 'name': '加时赛后' , type: 3}, 
                    99: { id: 99, name_en: "待定", name_zh: "待定", name: "待定" },
                    100: { 'id': 100, 'name_en': 'ENDED', 'name_zh': '完场', 'name': '完场' , type: 3}, 
                    14: { id: 14, name_en: "POSTPONED", name_zh: "延期", name: "延期" },
                    15: { id: 15, name_en: "DELAYED", name_zh: "推迟开赛", name: "推迟开赛" },
                    16: { id: 16, name_en: "CANCELED", name_zh: "比赛取消", name: "比赛取消" },
                    17: { id: 17, name_en: "INTERRUPTED", name_zh: "中断", name: "中断" },
                    18: { id: 18, name_en: "STOPED", name_zh: "停赛", name: "停赛" },
                    19: { id: 19, name_en: "ABANDONED", name_zh: "弃赛", name: "弃赛" },
                    20: { id: 20, name_en: "WALKOVER", name_zh: "不战而胜", name: "不战而胜", type: 3 },
                    21: { id: 21, name_en: "RETIRED", name_zh: "退出", name: "退出" },
                }
            },
            24: {
                // 羽毛球 badminton
                map: { 
                    0: { id: 0, name_en: "ERROR", name_zh: "比赛异常", name: "比赛异常" },
                    1: { 'id': 1, 'name_en': 'NOT_STARTED', 'name_zh': '未开赛', 'name': '未开赛' , type: 1}, 
                    3: { id: 3, name_en: "IN_PROGRESS", name_zh: "进行中", name: "进行中", type: 2 },
                    51: { 'id': 51, 'name_en': 'FIRST_SET', 'name_zh': '第一盘', 'name': '第一盘' , type: 2}, 
                    331: { 'id': 331, 'name_en': 'FIRST_PAUSE', 'name_zh': '第一盘结束', 'name': '第一盘结束' , type: 2}, 
                    52: { 'id': 52, 'name_en': 'SECOND_SET', 'name_zh': '第二盘', 'name': '第二盘' , type: 2}, 
                    332: { 'id': 332, 'name_en': 'SECOND_PAUSE', 'name_zh': '第二盘结束', 'name': '第二盘结束' , type: 2}, 
                    53: { 'id': 53, 'name_en': 'THIRD_SET', 'name_zh': '第三盘', 'name': '第三盘' , type: 2}, 
                    333: { 'id': 333, 'name_en': 'THIRD_PAUSE', 'name_zh': '第三盘结束', 'name': '第三盘结束' , type: 2}, 
                    54: { 'id': 54, 'name_en': 'FOURTH_SET', 'name_zh': '第四盘', 'name': '第四盘' , type: 2},
                    334: { 'id': 334, 'name_en': 'FOURTH_PAUSE', 'name_zh': '第四盘结束', 'name': '第四盘结束', type: 2 }, 
                    55: { 'id': 55, 'name_en': 'FIFTH_SET', 'name_zh': '第五盘', 'name': '第五盘' , type: 2}, 
                    100: { 'id': 100, 'name_en': 'ENDED', 'name_zh': '完场', 'name': '完场' , type: 3}, 
                    22: { 'id': 22, 'name_en': 'WALKOVER1', 'name_zh': '选手1不战而胜', 'name': '选手1不战而胜' , type: 3}, 
                    23: { 'id': 23, 'name_en': 'WALKOVER2', 'name_zh': '选手2不战而胜', 'name': '选手2不战而胜' , type: 3}, 
                    24: { 'id': 24, 'name_en': 'RETIRED1', 'name_zh': '选手1退赛', 'name': '选手1退赛' , type: 3}, 
                    25: { 'id': 25, 'name_en': 'RETIRED2', 'name_zh': '选手2退赛', 'name': '选手2退赛' , type: 3}, 
                    26: { 'id': 26, 'name_en': 'DEFAULTED1', 'name_zh': '选手1违约，选手2获胜', 'name': '选手1违约，选手2获胜' , type: 3}, 
                    27: { 'id': 27, 'name_en': 'DEFAULTED2', 'name_zh': '选手2违约，选手1获胜', 'name': '选手2违约，选手1获胜' , type: 3}, 
                    14: { id: 14, name_en: "POSTPONED", name_zh: "延期", name: "延期" },
                    15: { id: 15, name_en: "DELAYED", name_zh: "推迟开赛", name: "推迟开赛" },
                    16: { id: 16, name_en: "CANCELED", name_zh: "比赛取消", name: "比赛取消" },
                    17: { id: 17, name_en: "INTERRUPTED", name_zh: "中断", name: "中断" },
                    18: { id: 18, name_en: "STOPED", name_zh: "停赛", name: "停赛" },
                    19: { id: 19, name_en: "ABANDONED", name_zh: "弃赛", name: "弃赛" },
                    20: { id: 20, name_en: "WALKOVER", name_zh: "不战而胜", name: "不战而胜", type: 3 },
                    21: { id: 21, name_en: "RETIRED", name_zh: "退出", name: "退出" },
                }
            },
        },
        weather: { 0: { 'id': 0, 'name': '无数据' }, 1: { 'id': 1, 'name': 'Clear/Sunny' }, 2: { 'id': 2, 'name': 'Partly Cloudy' }, 3: { 'id': 3, 'name': 'Cloudy' }, 4: { 'id': 4, 'name': 'Overcast' }, 5: { 'id': 5, 'name': 'Mist' }, 6: { 'id': 6, 'name': 'Patchy rain nearby' }, 7: { 'id': 7, 'name': 'Patchy snow nearby' }, 8: { 'id': 8, 'name': 'Patchy sleet nearby' }, 9: { 'id': 9, 'name': 'Thundery outbreaks in nearby' }, 10: { 'id': 10, 'name': 'Fog' }, 11: { 'id': 11, 'name': 'Freezing fog' }, 12: { 'id': 12, 'name': 'Patchy light drizzle' }, 13: { 'id': 13, 'name': 'Light drizzle' }, 14: { 'id': 14, 'name': 'Patchy light rain' }, 15: { 'id': 15, 'name': 'Light rain' }, 16: { 'id': 16, 'name': 'Moderate rain at times' }, 17: { 'id': 17, 'name': 'Moderate rain' }, 18: { 'id': 18, 'name': 'Heavy rain at times' }, 19: { 'id': 19, 'name': 'Heavy rain' }, 20: { 'id': 20, 'name': 'Light freezing rain' }, 21: { 'id': 21, 'name': 'Light sleet' }, 22: { 'id': 22, 'name': 'Moderate or heavy sleet' }, 23: { 'id': 23, 'name': 'Patchy light snow' }, 24: { 'id': 24, 'name': 'Light snow' }, 25: { 'id': 25, 'name': 'Moderate snow' }, 26: { 'id': 26, 'name': 'Patchy heavy snow' }, 27: { 'id': 27, 'name': 'Heavy snow' }, 28: { 'id': 28, 'name': 'Light rain' }, 29: { 'id': 29, 'name': 'Moderate or heavy rain shower' }, 30: { 'id': 30, 'name': 'Torrential rain shower' }, 31: { 'id': 31, 'name': 'Light sleet showers' }, 32: { 'id': 32, 'name': 'Light snow showers' }, 33: { 'id': 33, 'name': 'Moderate or heavy snow showers' }, 34: { 'id': 34, 'name': 'Patchy light rain in area with thunder' }, 35: { 'id': 35, 'name': 'Moderate or heavy rain in area with thunder' } },
        grounds: { 0: { 'id': 0, 'name_zh': '无数据', 'name_en': '无数据', 'main': '无数据', 'name': '无数据' }, 1: { 'id': 1, 'name_zh': '硬地', 'name_en': 'Hard', 'main': 'Hardcourt', 'name': '硬地' }, 2: { 'id': 2, 'name_zh': '草地', 'name_en': 'Grass', 'main': 'Grass', 'name': '草地' }, 3: { 'id': 3, 'name_zh': '黏土', 'name_en': 'Clay', 'main': 'Clay', 'name': '黏土' }, 4: { 'id': 4, 'name_zh': '黏土', 'name_en': 'Clay', 'main': 'Clay', 'name': '黏土' }, 5: { 'id': 5, 'name_zh': '室外硬场地', 'name_en': 'Hardcourt outdoor', 'main': 'Hardcourt', 'name': '室外硬场地' }, 6: { 'id': 6, 'name_zh': '室内地摊', 'name_en': 'Carpet indoor', 'main': 'Indoor', 'name': '室内地摊' }, 7: { 'id': 7, 'name_zh': '室内人造草坪', 'name_en': 'Synthetic indoor', 'main': 'Indoor', 'name': '室内人造草坪' }, 8: { 'id': 8, 'name_zh': '室外人造草坪', 'name_en': 'Synthetic outdoor', 'main': 'Hardcourt', 'name': '室外人造草坪' }, 9: { 'id': 9, 'name_zh': '室内硬场地', 'name_en': 'Hardcourt indoor', 'main': 'Indoor', 'name': '室内硬场地' }, 10: { 'id': 10, 'name_zh': '黏土', 'name_en': 'Clay', 'main': 'Clay', 'name': '黏土' } },
        languages:{
            en:{'lang': 'en', 'desc': '英语', 'desc_en': 'English'}, 
            zh:{'lang': 'zh', 'desc': '中文（简体）', 'desc_en': 'Chinese (simplified)'}, 
            zht:{'lang': 'zht', 'desc': '中文（繁体）', 'desc_en': 'Chinese (traditional)'}, 
            es:{'lang': 'es', 'desc': '西班牙语', 'desc_en': 'Spanish'}, 
            de:{'lang': 'de', 'desc': '德语', 'desc_en': 'German'}, 
            it:{'lang': 'it', 'desc': '意大利语', 'desc_en': 'Italian'}, 
            pt:{'lang': 'pt', 'desc': '葡萄牙语', 'desc_en': 'Portuguese'}, 
            ru:{'lang': 'ru', 'desc': '俄语', 'desc_en': 'Russian'}, 
            fr:{'lang': 'fr', 'desc': '法语', 'desc_en': 'French'}, 
            ja:{'lang': 'ja', 'desc': '日语', 'desc_en': 'Japanese'}, 
            ko:{'lang': 'ko', 'desc': '韩语', 'desc_en': 'Korean'}, 
            tr:{'lang': 'tr', 'desc': '土耳其语', 'desc_en': 'Turkish'}, 
            th:{'lang': 'th', 'desc': '泰语', 'desc_en': 'Thai'}, 
            id:{'lang': 'id', 'desc': '印度尼西亚语', 'desc_en': 'Indonesian'}, 
            aa:{'lang': 'aa', 'desc': '阿拉伯语', 'desc_en': 'Arabic'}, 
            da:{'lang': 'da', 'desc': '丹麦语', 'desc_en': 'Danish'}, 
            sv:{'lang': 'sv', 'desc': '瑞典语', 'desc_en': 'Swedish'}, 
            nn:{'lang': 'nn', 'desc': '挪威语', 'desc_en': 'Norwegian'}, 
            bg:{'lang': 'bg', 'desc': '保加利亚语', 'desc_en': 'Bulgarian'}, 
            el:{'lang': 'el', 'desc': '希腊语', 'desc_en': 'Greek'}, 
            pl:{'lang': 'pl', 'desc': '波兰语', 'desc_en': 'Polish'}, 
            ro:{'lang': 'ro', 'desc': '罗马尼亚语', 'desc_en': 'Romanian'}, 
            cs:{'lang': 'cs', 'desc': '捷克语', 'desc_en': 'Czech'}, 
            hu:{'lang': 'hu', 'desc': '匈牙利语', 'desc_en': 'Hungarian'}, 
            sk:{'lang': 'sk', 'desc': '斯洛伐克语', 'desc_en': 'Slovak'}, 
            nl:{'lang': 'nl', 'desc': '荷兰语', 'desc_en': 'Dutch'}, 
            et:{'lang': 'et', 'desc': '爱沙尼亚语', 'desc_en': 'Estonian'}, 
            hr:{'lang': 'hr', 'desc': '克罗地亚语', 'desc_en': 'Croatian'}, 
            sl:{'lang': 'sl', 'desc': '斯洛文尼亚语', 'desc_en': 'Slovenian'}, 
            lv:{'lang': 'lv', 'desc': '拉脱维亚语', 'desc_en': 'Latvian'}, 
            fi:{'lang': 'fi', 'desc': '芬兰语', 'desc_en': 'Finnish'}, 
            bs:{'lang': 'bs', 'desc': '波斯尼亚语', 'desc_en': 'Bosnian'}, 
            sr:{'lang': 'sr', 'desc': '塞尔维亚语', 'desc_en': 'Serbian'}, 
            mk:{'lang': 'mk', 'desc': '马其顿语', 'desc_en': 'Macedonian'}, 
            lt:{'lang': 'lt', 'desc': '立陶宛语', 'desc_en': 'Lithuanian'}, 
            vi:{'lang': 'vi', 'desc': '越南语', 'desc_en': 'Vietnamese'}, 
            ch:{'lang': 'ch', 'desc': '瑞士德语', 'desc_en': 'Swiss German'}, 
            aze:{'lang': 'aze', 'desc': '阿塞拜疆语', 'desc_en': 'Azerbaijan'}, 
            ka:{'lang': 'ka', 'desc': '格鲁吉亚语', 'desc_en': 'Georgian'}, 
            sqi:{'lang': 'sqi', 'desc': '阿尔巴尼亚语', 'desc_en': 'Albanian'}, 
            ukr:{'lang': 'ukr', 'desc': '乌克兰语', 'desc_en': 'Ukrainian'}, 
            br:{'lang': 'br', 'desc': '巴西葡萄牙语', 'desc_en': 'Brazilian Portuguese'}, 
            srl:{'lang': 'srl', 'desc': '塞尔维亚拉丁语', 'desc_en': 'Serbian Latin'}, 
            mm:{'lang': 'mm', 'desc': '缅甸语', 'desc_en': 'Burmese'}, 
            km:{'lang': 'km', 'desc': '柬埔寨高棉语', 'desc_en': 'Cambodian Khmer'}
        },
        sports: {
            1: {"name_en": "football", "name_zh": "足球", icon: 'ios-football', logo: '', },
            2: {"name_en": "basketball", "name_zh": "篮球", icon: 'ios-basketball', logo: '', },
            3: {"name_en": "tennis", "name_zh": "网球", icon: 'ios-tennisball', logo: '', },
            5: {"name_en": "cricket", "name_zh": "板球", icon: '', logo: 'cricket.png', },
            6: {"name_en": "baseball", "name_zh": "棒球", icon: '', logo: 'baseball.png', },
            7: {"name_en": "handball", "name_zh": "手球", icon: 'ios-hand-outline', logo: '', },
            8: {"name_en": "ice_hockey", "name_zh": "冰球", icon: '', logo: 'ice_hockey.png', },
            10: {"name_en": "volleyball", "name_zh": "排球", icon: '', logo: 'volleyball.png', },
            11: {"name_en": "table_tennis", "name_zh": "乒乓球", icon: '', logo: 'table_tennis.png', },
            17: {"name_en": "american_football", "name_zh": "美式橄榄球", icon: '', logo: 'american_football.png', },
            19: {"name_en": "snooker", "name_zh": "斯诺克", icon: '', logo: 'snooker.png', },
            22: {"name_en": "waterpolo", "name_zh": "水球", icon: 'ios-water-outline', logo: '', },
            24: {"name_en": "badminton", "name_zh": "羽毛球", icon: '', logo: 'badminton.png', },
        },
        score_boxes : {
            1: {"key": 'p', "desc": "盘", "type": 1,},
            2: {"key": 'x', "desc": "抢七", "type": 2,},
            3: {"key": 'ft', "desc": "总比分", "type": 3,},
            4: {"key": 'pt', "desc": "实时局比分", "type": 4,},
            5: {"key": 'ot', "desc": "加时赛比分", "type": 5,},
            6: {"key": 'ap', "desc": "点球比分", "type": 6,},
            7: {"key": 'h', "desc": "安打计数总和", "type": 7,},
            8: {"key": 'e', "desc": "整队在防守时的失误次数", "type": 8,},
        },
        bracket: {
            type_id: {  // 赛制说明
                1: [  // 足球
                    {"id": 0, name: '未知'},
                    {"id": 1, name: '单场决胜制'},
                    {"id": 2, name: '单场决胜制，如果平局将重赛'},
                    {"id": 3, name: '单场决胜制，（存在重赛情况），人工判定胜者'},
                    {"id": 4, name: '单场决胜制，人工判定胜者'},
                    {"id": 5, name: '两回合制，包含客场进球规则'},
                    {"id": 6, name: '两回合制，人工判定胜者'},
                    {"id": 7, name: '人工判定胜者'},
                    {"id": 11, name: '两回合制'},
                ],
                2: [
                    {"id": 0, name: '未知'},
                    {"id": 1, name: '单场决胜制'},
                    {"id": 8, name: '3局2胜制'},
                    {"id": 9, name: '5局3胜制'},
                    {"id": 10, name: '7局4胜制'},
                ],
                3: [
                    {"id": 0, name: '未知'},
                    {"id": 1, name: '单场决胜制'},
                    {"id": 2, name: '单场决胜制，如果平局将重赛'},
                    {"id": 3, name: '单场决胜制，（存在重赛情况），人工判定胜者'},
                    {"id": 4, name: '单场决胜制，人工判定胜者'},
                    {"id": 7, name: '人工判定胜者'},
                ],
                0: [
                    {"id": 0, name: '未知'},
                    {"id": 1, name: '单场决胜制'},
                    {"id": 2, name: '单场决胜制，如果平局将重赛'},
                    {"id": 3, name: '单场决胜制，（存在重赛情况），人工判定胜者'},
                    {"id": 4, name: '单场决胜制，人工判定胜者'},
                    {"id": 5, name: '两回合制，包含客场进球规则'},
                    {"id": 6, name: '两回合制，人工判定胜者'},
                    {"id": 7, name: '人工判定胜者'},
                    {"id": 8, name: '3局2胜制'},
                    {"id": 9, name: '5局3胜制'},
                    {"id": 10, name: '7局4胜制'},
                ],
            },
            state_id: { // 状态
                1: [
                    {"id": 0, name: '未知'},
                    {"id": 1, name: '未开赛'},
                    {"id": 2, name: '等待对手'},
                    {"id": 6, name: '进行中'},
                    {"id": 7, name: '主场胜'},
                    {"id": 8, name: '客场胜'},
                    {"id": 9, name: '取消'},
                    {"id": 10, name: '轮空'},
                    {"id": 11, name: '等待抽签'},
                ],
                2: [
                    {"id": 0, name: '未知'},
                    {"id": 1, name: '未开赛'},
                    {"id": 2, name: '等待对手'},
                    {"id": 6, name: '进行中'},
                    {"id": 7, name: '主场胜'},
                    {"id": 8, name: '客场胜'},
                    {"id": 9, name: '取消'},
                    {"id": 10, name: '轮空'},
                    {"id": 11, name: '等待抽签'},
                ],
                3: [
                    {"id": 0, name: '未知'},
                    {"id": 1, name: '未开赛'},
                    {"id": 2, name: '等待对手'},
                    {"id": 3, name: '种子队/种子选手'},
                    {"id": 4, name: '部分(种子队/种子选手)'},
                    {"id": 5, name: '种子队/种子选手 赛程'},
                    {"id": 6, name: '进行中'},
                    {"id": 7, name: '主场胜'},
                    {"id": 8, name: '客场胜'},
                    {"id": 9, name: '取消'},
                    {"id": 10, name: '轮空'},
                    {"id": 11, name: '等待抽签'},
                ],
                0: [
                    {"id": 0, name: '未知'},
                    {"id": 1, name: '未开赛'},
                    {"id": 2, name: '等待对手'},
                    {"id": 3, name: '种子队/种子选手'},
                    {"id": 4, name: '部分(种子队/种子选手)'},
                    {"id": 5, name: '种子队/种子选手 赛程'},
                    {"id": 6, name: '进行中'},
                    {"id": 7, name: '主场胜'},
                    {"id": 8, name: '客场胜'},
                    {"id": 9, name: '取消'},
                    {"id": 10, name: '轮空'},
                    {"id": 11, name: '等待抽签'},
                ],
            }
        },
        team_ranking_types: {
            1: [
                {id:1,name:"Fifa男子", },{id:2,name:"Fifa女子", },{id:3,name:"俱乐部", }
            ]
        }
    },
    olympics: {
        img_prefix: 'https://img-cdn.namitiyu.com',
        remarks: {
            '': {'remark': '', 'desc': '无备注'}, 
            'DNS': {'remark': 'DNS', 'desc': '未开始'}, 
            'DNF': {'remark': 'DNF', 'desc': '未完成'}, 
            'DQ': {'remark': 'DQ', 'desc': '取消资格'}, 
            'Q': {'remark': 'Q', 'desc': '获取资格'}, 
            'WR': {'remark': 'WR', 'desc': '世界纪录'}, 
            'OR': {'remark': 'OR', 'desc': '奥运会纪录'}, 
            'AF': {'remark': 'AF', 'desc': '非洲纪录'}, 
            'AM': {'remark': 'AM', 'desc': '美洲纪录'}, 
            'AS': {'remark': 'AS', 'desc': '亚洲纪录'}, 
            'EU': {'remark': 'EU', 'desc': '欧洲纪录'}, 
            'OC': {'remark': 'OC', 'desc': '大洋洲纪录'}, 
            'NR': {'remark': 'NR', 'desc': '国家纪录'}, 
            'WB': {'remark': 'WB', 'desc': '世界赛最好成绩'}, 
            'OB': {'remark': 'OB', 'desc': '奥运会最好成绩'}, 
            'PB': {'remark': 'PB', 'desc': '个人最好成绩'}, 
            'SB': {'remark': 'SB', 'desc': '赛季最好成绩'}, 
            '=': {'remark': '=', 'desc': '平纪录'}
        },
        schedule_types: {
            0: {id: 0, "name": '普通比赛'},
            1: {id: 1, "name": '金牌赛'},
            2: {id: 2, "name": '开幕式'},
            3: {id: 3, "name": '闭幕式'},
            4: {id: 4, "name": '颁奖仪式'},
            5: {id: 5, "name": '铜牌赛'},
            6: {id: 6, "name": '金银铜赛'},
        },
        result_units: {
            0: {id: 0, "name": '未知'},
            1: {id: 1, "name": '分数(point)'},
            2: {id: 2, "name": '时间(Time)'},
            3: {id: 3, "name": '比分(X:Y)'},
            4: {id: 4, "name": '环(Point)'},
            5: {id: 5, "name": '公斤(KG)'},
            6: {id: 6, "name": '米(M)'},
            7: {id: 7, "name": '杆(Stroke)'},
            8: {id: 8, "name": '时间差(Time_diff)'},
        },
        match_status: {
            0: { id: 0, name_en: "ERROR", name_zh: "比赛异常", name: "比赛异常" },
            1: { id: 1, name_en: "NOT_STARTED", name_zh: "未开赛", name: "未开赛", type: 1 },
            3: { 'id': 3, 'name_en': 'IN_PROGRESS', 'name_zh': '进行中', 'name': '进行中' , type: 2}, 
            14: { id: 14, name_en: "POSTPONED", name_zh: "延期", name: "延期" },
            16: { id: 16, name_en: "CANCELED", name_zh: "比赛取消", name: "比赛取消" },
            17: { id: 17, name_en: "INTERRUPTED", name_zh: "中断", name: "中断" },
            20: { id: 20, name_en: "DaiDing", name_zh: "待定", name: "待定" },
            21: { id: 21, name_en: "RETIRED", name_zh: "退出", name: "退出" },
            100: { id: 100, name_en: "ENDED", name_zh: "完场", name: "完场", type: 3 },
        },
    },
    sports: {
        1: { id: 1, name: '足球'},
        2: { id: 2, name: '篮球'},
        3: { id: 3, name: '网球'},
        5: { id: 5, name: '板球'},
        6: { id: 6, name: '棒球'},
        7: { id: 7, name: '手球'},
        8: { id: 8, name: '冰球'},
        10: { id: 10, name: '排球'},
        11: { id: 11, name: '乒乓球'},
        16: { id: 16, name: '曲棍球'},
        17: { id: 17, name: '美式橄榄球'},
        19: { id: 19, name: '斯洛克'},
        22: { id: 22, name: '水球'},
        24: { id: 24, name: '羽毛球'},
        101: { id: 101, name: 'LOL'},
        102: { id: 102, name: 'CSGO'},
        103: { id: 103, name: 'DOTA2'},

    },
    games: {
        img_prefix: 'https://esports-cdn.namitiyu.com',
        games: {
            1: {id: 1, name:"Lol", "name_en":"lol", icon: '', logo: 'lol.png', img:'lol'},
            2: {id: 2, name:"Csgo", "name_en":"csgo", icon: '', logo: 'csgo.png', img:'csgo'},
            3: {id: 3, name:"Dota2","name_en":"dota2", icon: '', logo: 'dota2.png', img:'dota'},
            6: {id: 6, name:"Kog", "name_en":"kog", icon: '', logo: 'kog.png', img:'kog'},
        },
        status: {
            map: {
                1: { id: 1, name_en: "NotStart", name_zh: "未开始", name: "未开始", type:1, },
                2: { id: 2, name_en: "InProgress", name_zh: "进行中", name: "进行中", type:2, },
                3: { id: 3, name_en: "Ended", name_zh: "已结束", name: "已结束", type:3, },
            }
        },
        tier: { // 赛事种类
            1: {  // game_id
                0: {'id': 0, 'name': '全部赛事', 'name_en': 'All'},
                1: {'id': 1, 'name': '全球性', 'name_en': 'Global'},
                2: {'id': 2, 'name': '五大联赛', 'name_en': 'Five Leagues'},
                3: {'id': 3, 'name': '地区联赛', 'name_en': 'Regional',},
                4: {'id': 4, 'name': '其他赛事', 'name_en': 'Other',},
            },
            2: {
                0: {'id': 0, 'name': '全部赛事', 'name_en': 'All'},
                2: {'id': 2, 'name': 'Major', 'name_en': 'Major'},
                3: {'id': 3, 'name': '线下赛', 'name_en': ' LAN'},
                4: {'id': 4, 'name': '线上赛', 'name_en': 'Online'},
                5: {'id': 5, 'name': '其他赛事', 'name_en': 'Other',},
            },
            3: {
                0: {'id': 0, 'name': '全部赛事', 'name_en': 'All'},
                1: {'id': 1, 'name': '国际邀请赛', 'name_en': 'Ti'},
                2: {'id': 2, 'name': '甲级联赛', 'name_en': 'Major'},
                3: {'id': 3, 'name': '乙级联赛', 'name_en': 'Minor'},
                4: {'id': 4, 'name': '预选赛', 'name_en': 'Qualifier'},
                5: {'id': 5, 'name': '其他赛事', 'name_en': 'Other',},
            },
            6: {
                0: {'id': 0, 'name': '全部赛事', 'name_en': 'All'},
                1: {'id': 1, 'name': '冠军杯', 'name_en': 'Champion'},
                2: {'id': 2, 'name': 'KPL', 'name_en': 'KPL',},
                3: {'id': 3, 'name': 'KRKPL', 'name_en': 'KRKPL',},
                4: {'id': 4, 'name': '其他赛事', 'name_en': 'Other',},
            }
        },
        positions: {
            3: { // game_id
                1: {id:1, name: '一号位'},
                2: {id:2, name: '二号位'},
                3: {id:3, name: '三号位'},
                4: {id:4, name: '四号位'},
                5: {id:5, name: '五号位'},
            },
            1: { // game_id
                1: {id:1, name: 'ADC'},
                2: {id:2, name: '中单'},
                3: {id:3, name: '上单'},
                4: {id:4, name: '打野'},
                5: {id:5, name: '辅助'},
            },
            6: { // game_id
                1: {id:1, name: '对抗路'},
                2: {id:2, name: '打野'},
                3: {id:3, name: '中路'},
                4: {id:4, name: '发育路'},
                5: {id:5, name: '游走'},
            },
        }
    }
}