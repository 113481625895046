import { asyncRouterMap, constantRouterMap } from '@/router';
import router from '../../router';

/**
 * Use meta.role to determine if the current user has permission
 * @param router_names  后端返回的权限列表-名称
 * @param route 
 */
function hasPermission(router_names, route) {
  // console.log(route);
  if (route.meta.hideMenu){
    return true
  }
  // return router_names.includes( route.name ) // 动态路由的名称 包含在返回的权限列表中
  if (router_names[route.name]){
    return true
  }else{
    return false
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param router_names
 */
export function filterAsyncRoutes(routes, router_names) {
  const res = []
  routes.forEach(route => {
    const tmp = { ...route }
    if ( hasPermission(router_names, tmp) ) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, router_names)
      }
      res.push(tmp)
    }
  })
  return res
}

const state = {
  routes: [],
  addRoutes: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    // console.log("alert: ", routes)
    state.addRoutes = routes
    state.routes = constantRouterMap.concat(routes)
  }
}

const actions = {
  generateRoutes({ commit }, router_names ) {
    return new Promise(resolve => {
      let accessedRoutes
      // console.log(router_names, asyncRouterMap)
      if (router_names === true) {
        // 超级管理员直接返回true字段 而不是一个字典
        accessedRoutes = asyncRouterMap || []
      }else if (router_names === false) {
        // false 代表毫无权限
        accessedRoutes = []
      } else {
        accessedRoutes = filterAsyncRoutes(asyncRouterMap, router_names)
      }
      // console.log("accessedRoutes: ", accessedRoutes)
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  }
}

// console.log(state)

export default {
  namespaced: true,
  state,
  mutations,
  actions
}