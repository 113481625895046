export default {
    namespaced: true,
    state: {
        uid: 0,
        name: '',
        name_py: '',
        avatar: '',
        access_token: "",    // ''
        group_id: 0,
        group_name: "",
        route_names: false,    // null 权限路由
    },
    mutations: {
        loginSuccess(state, user){
            state.uid = user.uid;
            state.name =  user.name;
            state.name_py =  user.name_py;  // 拼音
            state.avatar = user.avatar;
            state.access_token = user.access_token;
            state.group_id = user.group_id;
            state.group_name = user.group_name;
            state.route_names = user.route_names;
        },
        logout(state){
            state.uid = 0;
            state.name =  '';
            state.name_py =  '';
            state.avatar = '';
            state.access_token = '';
            state.group_id = 0;
            state.group_name = '';
            state.route_names = false;
        },
    },
    getters: {
        // doneTodos: state => {
        //   return state.todos.filter(todo => todo.done)
        // }
    },
    actions: {
        // 登出
        logoutSuccess({commit}){
            commit('logout');
            return true
        },
        // 
    },
}