import http from '@/utils/http';

// 获取国家/地区
export const ddCallback = (params) => {
    return http.request({
        url: "/v1/oauth2/dd/callback",
        method: 'get',
        params: params,
    })
}

export const casCallback = (params) => {
    return http.request({
        url: "/v1/oauth2/cas/callback",
        method: 'post',
        data: params,
    })
}

export const logout = (params) => {
    return http.request({
        url: "/v1/oauth2/logout",
        method: 'get',
        params: params,
    })
}

// export const uploadQiniu = (params) => {
//     return http.request({
//         url: "//upload.qiniup.com/",
//         method: 'post',
//         data: params,
//     })
// }