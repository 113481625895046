import Vue from 'vue'
import App from './App.vue'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import store from './store'
import router from './router'
import config from './config'
import Unit from '@/utils/util';
import VueLazyLoad from 'vue-lazyload'; 
import "tailwindcss/tailwind.css"

Vue.config.productionTip = false

Vue.use(VueLazyLoad, {
    lazyComponent: true,
})
Vue.use(ViewUI, {
  size: 'small',
  transfer: true,
});
Vue.prototype.$conf = config;
Vue.prototype.$unit = Unit;

Vue.component('remote-script', {
  render: function (createElement) {
      var self = this;
      return createElement('script', {
          attrs: {
              type: 'text/javascript',
              src: this.src
          },
          on: {
              load: function (event) {
                  self.$emit('load', event);
              },
              error: function (event) {
                  self.$emit('error', event);
              },
              readystatechange: function (event) {
                  if (this.readyState == 'complete') {
                      self.$emit('load', event);
                  }
              }
          }
      });
  },

  props: {
      src: {
          type: String,
          required: true
      }
  }
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
