import store from '@/store'
import axios from 'axios';

axios.defaults.timeout = 10000;  // 10s 自动超时
var APPID = "NANA-00001"
class HttpRequest {
    constructor() {
    }
    getInsideConfig() {
        // console.log("[utils/http.js]", store.state.user.access_token);
        let headers = {
            token: store.state.user.access_token || '',
            
            // 记录到阿里云的日志的参数
            'Nana-UserId': store.state.user.uid || '',
            'Nana-Username': store.state.user.name_py || encodeURI( store.state.user.name ) || '',
            'Nana-Token': store.state.user.access_token || '',
            'Nana-AppId': APPID,
            'Nana-Env': process.env.NODE_ENV,
            
            // menu: store.state.app.currentRouter ? store.state.app.currentRouter.name : ''
        }
        const config = {
            headers: headers
        }
        return config
    }

    interceptors(instance, url) {
        // 请求拦截
        instance.interceptors.request.use(config => {
            // // 每次发送请求之前判断vuex中是否存在token 
            // // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
            // // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断 
            // const token = store.state.token; 
            // token && (config.headers.Authorization = token);      
            return config;
        }, error => {
            return Promise.reject(error);
        }
        )
        // 响应拦截
        instance.interceptors.response.use(res => {
            // const { data, status } = res
            // return { data, status }
            return res
        }, error => {
            // console.log("error: ", error )
            if (error.response && error.response.status) {
                switch (error.response.status) {
                    // 401: 未登录
                    // 未登录则跳转登录页面，并携带当前页面的路径
                    // 在登录成功后返回当前页面，这一步需要在登录页操作。 
                    case 401:
                        // router.replace({ 
                        // path: '/login', 
                        // query: { 
                        // redirect: router.currentRoute.fullPath 
                        // }
                        // });
                        break;
                    // 403 token过期
                    // 登录过期对用户进行提示
                    // 清除本地token和清空vuex中token对象
                    // 跳转登录页面
                    case 403:
                        // Toast({
                        // message: '登录过期，请重新登录',
                        // duration: 1000,
                        // forbidClick: true
                        // });
                        // 清除token
                        localStorage.removeItem('token');
                        // store.commit('loginSuccess', null);
                        // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面 
                        setTimeout(() => {
                            router.replace({
                                path: '/login',
                                query: {
                                    redirect: router.currentRoute.fullPath
                                }
                            });
                        }, 1000);
                        break;

                    // 404请求不存在
                    case 404:
                        Toast({
                            message: '网络请求不存在',
                            duration: 1500,
                            forbidClick: true
                        });
                        break;
                    // 其他错误，直接抛出错误提示
                    default:
                        // Toast({
                        // message: error.response.data.message,
                        // duration: 1500,
                        // forbidClick: true
                        // });
                        break;
                }
                return Promise.reject(error);
            }else{
                return Promise.reject(error);
            }
        })
    }
    request(options) {
        const instance = axios.create();
        options = Object.assign(this.getInsideConfig(), options);
        this.interceptors(instance, options.url);
        return instance(options);
    }
}
const http = new HttpRequest()
export default http
