<template>
    <Layout  class=" h-screen ">
        <Sider ref="side1" class='overflow-y-auto relative' hide-trigger	collapsible :collapsed-width="100" v-model="isCollapsed"   >
            <div :class="isCollapsed? 'flex justify-center overflow-hidden ': 'flex justify-center' ">
                <img class="my-4" :src="require('@/assets/logo.png')"  />
            </div>
            <Menu :active-name="parent_active_name" theme="dark" width="auto" :class="menuitemClasses" @on-select="onSelectParentMenu">
                <MenuItem class='menu-item flex justify-center' v-for="(item, index) in menus_parent" :key="item.name" :name="item.name" :to="item.path" v-if="! item.meta.hideMenu && (!item.meta.group)">
                    <Icon v-if="item.meta.icon" :type="item.meta.icon" />
                    <img  :class="isCollapsed? 'menu-icon-collapsed': 'menu-icon'" v-else-if="item.meta.logo" :src="item.meta.logo" />
                    <span>{{item.meta.title}}</span>
                </MenuItem>

                <Submenu class='menu-item' :name="submenu" v-for="(_list,submenu) in sub_menus" :key="submenu">
                    <template slot="title">
                        <Icon type="ios-game-controller-b-outline" />
                        <span>{{sub_menu_groups[submenu]}}</span>
                    </template>
                    <MenuItem class='menu-item flex' v-for="(item, index) in _list" :key="item.name" :name="item.name" :to="item.path" v-if="! item.meta.hideMenu">
                        <Icon v-if="item.meta.icon" :type="item.meta.icon" />
                        <img :class="isCollapsed? 'menu-icon-collapsed': 'menu-icon'" v-else-if="item.meta.logo" :src="item.meta.logo" />
                        <span>{{item.meta.title}}</span>
                    </MenuItem>
                </Submenu>
                <!-- 一个空行占位 : 防止最后的目录项目被侧边缩符号遮挡 -->
                <!-- <MenuItem class='menu-item ' name="empty" >
                    <br />
                </MenuItem> -->
            </Menu>
        </Sider>
        <Layout>
            <div class="flex flex-row flex-wrap bg-white relative ">
                <div class="flex flex-row flex-wrap items-center float-left ">
                    <Icon @click.native="collapsedSider" :class="isCollapsed? 'transform -rotate-90': '' + ' pl-4 ' " color='black' type="md-menu" size="20"></Icon>
                    <subMenu v-for="(item, index) in menus_children_map[parent_active_name] || []" 
                        :key="item.name"  v-if="! item.meta.hideMenu" :menu="item" @on-click='onClickChildMenu' :activated='child_active_name == item.name'>
                    </subMenu>
                </div>
                <div class="items-center m-auto m-0 mr-5  " >
                    <Dropdown trigger="click"  @on-click="languagesClicked" class="px-3" >
                        <Button type="success" ghost>
                            <span class='text-main'>
                            {{ $conf.ballsports.languages[lang]? $conf.ballsports.languages[lang].desc : '中文简体' }}
                            </span>
                        </Button>
                        <DropdownMenu slot="list">
                            <DropdownItem v-for="item in $conf.ballsports.languages" :key="item.lang" :name="item.lang">
                                <span class="text-main">{{item.desc}}</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    <Dropdown trigger="click"  @on-click="userMenuClicked">
                        <Avatar shape='square' size="default" :src="user.avatar" v-if="user.avatar"></Avatar>
                        <Avatar shape='square' size="default" class='bg-green-500' v-else>{{user.name}}</Avatar>
                        <DropdownMenu slot="list">
                            <DropdownItem name="index"><span class="text-main">个人主页</span></DropdownItem>
                            <DropdownItem disabled><span class="text-main">{{user.group_name}}</span></DropdownItem>
                            <DropdownItem name="logout"><span class="text-main">登出</span></DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>
            <Layout >
                <Sider class='bg-white' :collapsible='false' v-model="isThirdCollapsed"  v-show="third_menu && third_menu.length > 0" >
                    <Menu ref="third_menu" theme="light" width="auto" :active-name="third_active_name">
                        <MenuItem v-for="(item, index) in third_menu || []" :key="item.name" :name="item.name" :to="item.path" >
                            <Icon v-if="item.meta.icon" :type="item.meta.icon" />
                            <span >{{item.meta.title}}</span>
                        </MenuItem>
                    </Menu>
                </Sider>
                <Content>
                    <Card>
                        <router-view v-if = 'isRouterAlive'></router-view>
                    </Card>
                </Content>
            </Layout>
        </Layout>
    </Layout>
</template>
<script>

import { asyncRouterMap, constantRouterMap } from '@/router';
import store  from '@/store';
// import {getMenu} from '@/api/ballsports/menu/menu';
import subMenu from './sub-menu.vue';
import {logout} from '@/api/login';


export default {
    // provide(){
    //     return{
    //         reload:this.reload
    //     }
    // },
    components:{
        subMenu
    },
    data () {
        return {
            lang: "",
            isCollapsed: false,
            isThirdCollapsed: false,
            /*
            route_names: {
                "主menu_name": {
                    id: 1,
                    parent: 0
                },
               "子menu_name": {
                     id: 2,
                     parent: 1,
               }
            }
            */
            isRouterAlive:true, 
            route_names: {},
            menus_parent: [],  // 一级目录
            menus_children_map: {}, // 一级目录名称对应的:二级目录
            menus_parent_map : {}, // 一级目录名称对应的自身
            menus_second_map: {},   // 二级目录名称对应的children
            sub_menus : {}, // 折叠目录
            sub_menu_groups: { 'games': "电竞", 'relation':'关联' },
            parent_active_name: "",
            child_active_name : "",
            third_active_name: '',

            parent_menu_meta_title: '',
            user: {}, // 当前登录用户的信息

            // 第三个目录 - 关联里
            third_menu : [],
        };
    },
    computed: {
        menuitemClasses: function () {
            return [
                'menu-item ',
                this.isCollapsed ? 'collapsed-menu' : ''
            ]
        }
    },

    mounted() {
        // console.log(this.$route.path);
        this.lang = sessionStorage.getItem("lang") || 'zh';
        this.getUser();
        this.getData();
        this.onMenuSelected();
        this.getParentMenuTitle();
    },

    methods : {
        getUser(){
            let self = this;
            self.user.uid = self.$store.state.user.uid;
            self.user.name = self.$store.state.user.name;
            self.user.avatar = self.$store.state.user.avatar;
            self.user.group_id = self.$store.state.user.group_id;
            self.user.group_name = self.$store.state.user.group_name;
        },
        // end 
        hasPermission(route_names, route) {
            // return route_names.includes( route.name ) // 动态路由的名称 包含在返回的权限列表中
            if (route_names[route.name]){
                return true
            }else{
                return false
            }
        },
        // end
        updateMenusSecondMap(child){
            // 更新页面内的目录
            if (child.children){
                this.menus_second_map[ child.name ] = child.children;
            }
        },
        // end 
        // 获取目录
        getData () {
            let self = this;
            self.route_names = store.state.user.route_names;
            if ( self.route_names === true ){
                // 超级管理员权限
                asyncRouterMap.forEach(route => {
                    const tmp = { ...route }
                    self.menus_parent.push( tmp );
                    self.menus_children_map[ tmp.name ] = [];
                    self.menus_parent_map[tmp.name] = tmp;
                    tmp.children.forEach(child => {
                        self.menus_children_map[tmp.name].push(child);
                        self.updateMenusSecondMap(child);
                    })
                    // 更新到折叠目录
                    if (tmp.meta.group){
                        if (!self.sub_menus[tmp.meta.group]){
                            self.sub_menus[tmp.meta.group] = []
                        }
                        self.sub_menus[tmp.meta.group].push( tmp )
                    }
                })
            }else{
                asyncRouterMap.forEach(route => {
                    const tmp = { ...route }
                    if ( self.hasPermission(self.route_names, tmp) ) {
                        self.menus_parent.push( tmp );
                        self.menus_children_map[ tmp.name ] = [];
                        self.menus_parent_map[tmp.name] = tmp;
                        if (tmp.children) {
                            tmp.children.forEach(child => {
                                if (self.hasPermission(self.route_names, child)){
                                    self.menus_children_map[tmp.name].push(child);
                                    self.updateMenusSecondMap(child);
                                }
                            })
                        }
                        // 更新到折叠目录
                        if (tmp.meta.group){
                            if (!self.sub_menus[tmp.meta.group]){
                                self.sub_menus[tmp.meta.group] = []
                            }
                            self.sub_menus[tmp.meta.group].push( tmp )
                        }
                    }
                })
            }
            // console.log(self.sub_menus);
        },
        // end
        onSelectParentMenu(name){
            let self = this;
            self.parent_active_name = name;
            self.getParentMenuTitle();
            self.getChildMenuName();
            // console.log( self.parent_active_name, self.child_active_name )
        },
        // end
        initThirdMenu(){
            let self = this;
            // 获取子目录
            let third_menu = self.menus_second_map[self.child_active_name] ;
            // console.log(third_menu);
            if (third_menu && third_menu.length > 0){
                self.third_menu = third_menu;
            }else{
                self.third_menu = [];
            }
            return third_menu;
        },
        onClickChildMenu(menu){
            let self = this;
            // console.log(menu)
            if (menu && menu.name !== self.child_active_name){
                self.$router.push(menu);
                self.child_active_name = menu.name;
                self.initThirdMenu();
            }
        },
        // end
        onSelectChildMenu(name){
            console.log('onSelectChildMenu', name)
            let self = this;
            self.child_active_name = name;
            self.initThirdMenu();
        },
        // end
        // 获取当前选中的目录
        onMenuSelected(){
            let self = this;
            const route_path = this.$route.path;
            // console.log(route_path, self.menus_parent)
            self.menus_parent.forEach(route => {
                // console.log("Menu: ", route_path, route.path, route.path === route_path)
                if (route.path === route_path || route.path + '/' === route_path ){
                    self.parent_active_name = route.name;
                    var menu_children = self.menus_children_map[route.name];
                    if (menu_children && menu_children.length > 0){
                        const c_route = menu_children[0];
                        if (c_route){
                            self.child_active_name  = c_route.name;
                            third_menu = self.initThirdMenu();
                            if (third_menu && third_menu.length > 0){
                                self.$router.push(third_menu[0]);  // 跳转到详情页 
                            }else{
                                self.$router.push(c_route);  // 跳转到详情页 
                            }
                            return
                        }
                    }
                    return
                }else{
                    var menu_children = self.menus_children_map[route.name];
                    if (menu_children && menu_children.length > 0 ){
                        menu_children.forEach(c_route => {
                            if (c_route.path == route_path){
                                self.parent_active_name = route.name;
                                self.child_active_name  = c_route.name;
                                let third_menu = self.initThirdMenu();
                                if (third_menu && third_menu.length > 0){
                                    self.third_active_name = third_menu[0].name;
                                    self.$router.push(third_menu[0]);  // 跳转到详情页 
                                }
                                return
                            }else {
                                // 子目录下的第三级目录
                                let third_menu = c_route.children;
                                if (third_menu && third_menu.length > 0){
                                    third_menu.forEach(third_route => {
                                        if (third_route.path == route_path){
                                            self.parent_active_name = route.name;
                                            self.child_active_name  = c_route.name;
                                            self.third_active_name = third_route.name;
                                            self.initThirdMenu();
                                            return
                                        }
                                    })
                                }
                            }
                        })
                    }
                }
            })
        },
        // end 
        getParentMenuTitle(){
            let self = this;
            let parent_route = self.menus_parent_map[ self.parent_active_name ];
            self.parent_menu_meta_title = parent_route? parent_route.meta.title: '';
        },
        // end
        // 获取默认点击的子菜单
        getChildMenuName(){
            let self = this;
            // console.log(1, self.parent_active_name)
            var menu_children = self.menus_children_map[ self.parent_active_name ];
            if (menu_children && menu_children.length > 0){
                let child_menu = menu_children[0];
                // console.log(child_menu);
                self.child_active_name  = child_menu.name;
                self.initThirdMenu();
                // console.log(self.child_active_name)
                self.$nextTick(() => {
                    // this.$refs.side_menu.updateOpened()
                    if (this.$refs.child_menu){
                        this.$refs.child_menu.updateActiveName();
                    }
                    // 第三级别目录
                    let third_children = child_menu.children;
                    if (third_children && third_children.length > 0){
                        self.third_active_name = third_children[0].name;
                        // console.log(3, self.third_active_name);
                        if (this.$refs.third_menu){
                            // console.log(4, self.third_active_name);
                            this.$refs.third_menu.updateActiveName();
                        }
                        self.$router.push(third_children[0]);  // 跳转到详情页 (必须)
                    }
                })
            }else{
                self.child_active_name = '';
            }
        },
        // end 
        userLogout(){
            let self = this;

            let host = window.location.host;

            // 清除casdoor
            logout({
                host
            }).then(response => {
                // 删除用户缓存
                localStorage.removeItem( "user" );
                localStorage.removeItem('portal_user');
                localStorage.removeItem('thesports_user');
                
                if (response.data.code === 0) {
                    let urls = response.data.data;
                    let redirect_url = urls.redirect_url;
                    if (redirect_url){
                        // 跳转
                        window.location.href = redirect_url;
                    }else{
                        self.$router.push({path: '/login/index' }).catch(()=>{});
                    }
                } else {
                    self.$Message.error(response.data.msg);
                    self.msg = response.data.msg ;
                    self.$router.push({path: '/login/index' }).catch(()=>{});
                }
            })
        },
        // end
        userMenuClicked(item){
            let self = this;
            // console.log(item);
            if (item === 'logout') {
                self.userLogout();
            }else if (item === 'index'){
                self.$router.push({path: '/index' }).catch(()=>{});
            }
        },
        // end 
        languagesClicked(item){
            let self = this;
            // console.log('language clicked');
            self.lang = item || "zh";
        },
        // end
        collapsedSider () {
            this.$refs.side1.toggleCollapse();
        }
    },

    watch: {
        lang(value){
            let self = this;
            sessionStorage.setItem("lang", value);
            this.isRouterAlive = false;
            this.$nextTick( function () {
                this.isRouterAlive = true;
            })
        },
    }
}

</script>

<style scoped>
    @import "menu.css"
</style>