<template>
    <button :class="'flex justify-center items-center px-4 py-3 rounded ' + 
                    (activated ? ' bg-blue-100 ' :'  hover:bg-blue-50 ') " @click='onClick'>
        <span :class="(activated ? ' text-blue-500 ' : ' text-gray-600 ') +' text-sm whitespace-nowrap  '">{{ menu.meta.title }}</span>
    </button>
</template>
<script>

export default ({
    name: "sub-menu",
    props: {
        menu: Object,
        activated: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        let self = (window.x = this);
        return {
        }
    },
    methods: {
        onClick () {
            this.$emit("on-click", this.menu)
        },
        // end 
    },
})
</script>
